import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/317f3503/src/components/layouts/index.js";
import { Link } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Welcome to DS-Kit`}</h1>
    <p>{`DS-kit is the datastory design system. On this site you can find all the resources needed to create datastory products, including general branding guidelines on typography and color, as well as all the react components used in datastory products.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The resources are divided in design resources aimed at designers, and code + api documentation for developers.`}</p>
    </blockquote>
    <h2>{`Designers`}</h2>
    <p>{`If you are a designer, head on over to the DS-kit design section and start reading about ds-kit `}<Link to="/typography" mdxType="Link">{`typography`}</Link>{`, `}<Link to="/colors" mdxType="Link">{`colors`}</Link>{`, and `}<Link to="/templates" mdxType="Link">{`templates`}</Link>{`.`}</p>
    <h2>{`Developers`}</h2>
    <p>{`If you want to develop datastory products with code, get started with the DS-kit components.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      